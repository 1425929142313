import https from "./https"
import store from "@/store"

const organization = store.state.general.organization

const rewardMissionApi = {
  list(rewardCardId, params) {
    return https.get(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-missions`, {params})
  },
  get(rewardCardId, rewardMissionId) {
    return https.get(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-missions/${rewardMissionId}`)
  },
  store (rewardCardId, params) {
    return https.post(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-missions`, params)
  },
  update(rewardCardId, params) {
    return https.put(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-missions/${params.id}`, params)
  },
  remove(rewardCardId, rewardMissionId) {
    return https.delete(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-missions/${rewardMissionId}`)
  },
}

export default rewardMissionApi
